"use client";

import { useState, useEffect } from "react";
import Image from "next/image";
import { NAV_LINKS } from "../../constants/data";
import Link from "next/link";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import Backdrop from "./Backdrop";
import { useForm } from "../../context/FormContext";
import { usePathname } from "next/navigation";

export default function Navbar() {
  // const pagesWithoutNavbar = ["/property"]; // Add paths as needed
  const pathname = usePathname();
  const hideNavbar = pathname.startsWith("/property");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [email, setEmail] = useState("");
  const { openForm } = useForm();

  // Newsletter Mutation
  const newsletterMutation = useMutation({
    mutationFn: async (email) => {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/admin/newsletter`,
        { email }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Successfully subscribed to newsletter!");
      setEmail("");
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        toast.info(error.response.data.message);
      } else {
        toast.error("Failed to subscribe. Please try again later.");
      }
    },
  });

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    newsletterMutation.mutate(email);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      const navHeight = 100; // 20px padding-top from PageWrapper
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setActiveSection(sectionId);
    } else {
      window.location.href = "/?section=" + sectionId.replace("#", "");
    }
    setIsMenuOpen(false);
  };

  // Track scroll position for navbar transparency
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const goToPage = (page) => {
    // close menu
    setIsMenuOpen(false);
    window.location.href = `/#${page}`;
  };
  if (hideNavbar) return null;
  return (
    <>
      {/* Backdrop */}
      <Backdrop isOpen={isMenuOpen} onClose={toggleMenu} zIndex="z-[50]" />

      <nav
        className={`fixed top-0 left-0 right-0 z-[60] transition-all duration-300
        ${scrolled ? "bg-white/90 backdrop-blur-md shadow-lg" : "bg-transparent"}`}
      >
        <div className="max-w-[1920px] px-5 md:px-20 mx-auto">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <Link
              href="/"
              className="flex-shrink-0 transform hover:scale-105 transition-transform duration-200"
            >
              <Image
                src="/main10_green.png"
                width={103}
                height={27}
                alt="logo"
                className="h-8 w-auto"
              />
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex items-center space-x-8">
              {NAV_LINKS.map((item, index) => (
                <button
                  key={index}
                  onClick={() =>
                    scrollToSection(
                      `#${item.replace(/\s+/g, "").toLowerCase()}`
                    )
                  }
                  className={`relative px-3 py-2 text-sm font-medium transition-colors duration-200
                    group hover:text-primary
                    ${
                      activeSection ===
                      `#${item.replace(/\s+/g, "").toLowerCase()}`
                        ? "text-primary"
                        : "text-gray-600"
                    }`}
                  aria-label={`Go to ${item} section`}
                >
                  {item}
                  <span
                    className={`absolute bottom-0 left-0 w-full h-0.5 bg-primary transform origin-left 
                    transition-transform duration-200 scale-x-0 group-hover:scale-x-100
                    ${activeSection === `#${item.replace(/\s+/g, "").toLowerCase()}` ? "scale-x-100" : ""}`}
                  />
                </button>
              ))}
            </div>

            {/* Mobile Menu Button */}
            <div className="lg:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center rounded-md text-primary 
                  hover:bg-primary hover:text-white transition-colors duration-200 mt-5"
                aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                aria-expanded={isMenuOpen}
              >
                {isMenuOpen ? (
                  <svg
                    className="h-6 w-6"
                    stroke="#00EC70"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 5H8M20 12H4M20 19H12"
                      stroke="#00EC70"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className="relative lg:hidden">
          {/* Drawer */}
          <div
            className={`fixed top-0 right-0 w-full bg-primary shadow-xl 
              transform transition-transform duration-300 ease-in-out z-[60] h-screen
              ${isMenuOpen ? "translate-x-0" : "translate-x-full"}`}
          >
            {/* Header */}
            <div className="flex items-center justify-between px-6 h-20 border-b border-white/10">
              <Link
                href="/"
                className="text-[32px] font-semibold font-plus_jakarta_sans text-secondary"
              >
                <Image
                  src="/main10_green.png" // Make sure to have a white version of your logo
                  width={120}
                  height={32}
                  alt="main10"
                  className="h-8 w-auto"
                />
              </Link>
              <button
                onClick={toggleMenu}
                className="p-2 text-secondary hover:bg-white/10 rounded-full transition-colors"
                aria-label="Close menu"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Menu Content */}
            <div className="px-6 py-4 h-[calc(100vh-80px)] overflow-y-auto">
              {/* Navigation Links */}
              <div className="mb-8">
                {NAV_LINKS.map((item, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      scrollToSection(
                        `#${item.replace(/\s+/g, "").toLowerCase()}`
                      )
                    }
                    className="w-full text-left py-3 text-secondary hover:text-primary300 transition-colors"
                  >
                    {item}
                  </button>
                ))}
              </div>

              {/* Social Media */}
              <div className="mb-8">
                <h3 className="text-primary300 text-xl mb-4">SOCIAL MEDIA</h3>
                <div className="flex gap-4">
                  <a
                    href="https://www.linkedin.com/company/main10-in"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-primary300 p-3 rounded-full hover:bg-primary400 transition-colors"
                  >
                    <Image
                      src="/linkedin.svg"
                      width={24}
                      height={24}
                      alt="linkedin"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/main10.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-primary300 p-3 rounded-full hover:bg-primary400 transition-colors"
                  >
                    <Image
                      src="/instagram.svg"
                      width={24}
                      height={24}
                      alt="instagram"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/main10.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-primary300 p-3 rounded-full hover:bg-primary400 transition-colors"
                  >
                    <Image
                      src="/facebook.svg"
                      width={24}
                      height={24}
                      alt="facebook"
                    />
                  </a>
                </div>
              </div>

              {/* Learn More */}
              <div className="mb-8">
                <h3 className="text-primary300 text-xl mb-4">Learn more</h3>
                <div className="space-y-3">
                  <button
                    onClick={() => goToPage("insights")}
                    className="block text-secondary hover:text-primary300 text-left w-full"
                  >
                    Insights
                  </button>
                  <button
                    onClick={() => goToPage("faqs")}
                    className="block text-secondary hover:text-primary300 text-left w-full"
                  >
                    FAQs
                  </button>
                  <button
                    onClick={() => openForm()}
                    className="block text-secondary hover:text-primary300 text-left w-full"
                  >
                    Get a callback
                  </button>
                </div>
              </div>

              {/* Help */}
              <div className="mb-8">
                <h3 className="text-primary300 text-xl mb-4">Help</h3>
                <div className="space-y-3">
                  <button
                    onClick={() => openForm()}
                    className="block text-secondary hover:text-primary300 text-left w-full"
                  >
                    Contact Us
                  </button>
                  <Link
                    href="/terms-and-conditions"
                    className="block text-secondary hover:text-primary300"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    href="/privacy-policy"
                    className="block text-secondary hover:text-primary300"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>

              {/* Newsletter */}
              <div className="mb-20">
                <h3 className="text-primary300 text-xl mb-4">Newsletter</h3>
                <form onSubmit={handleNewsletterSubmit} className="space-y-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                    className="w-full px-4 py-3 rounded-lg bg-white/10 text-secondary 
                      placeholder:text-secondary/60 border-none focus:outline-none 
                      focus:ring-2 focus:ring-primary300"
                  />
                  <button
                    type="submit"
                    disabled={newsletterMutation.isLoading}
                    className="w-full bg-primary300 text-primary py-3 rounded-lg 
                      hover:bg-primary400 transition-colors font-medium disabled:opacity-70"
                  >
                    {newsletterMutation.isLoading
                      ? "Subscribing..."
                      : "Subscribe Now"}
                  </button>
                </form>
              </div>

              {/* Copyright */}
              <div className="fixed bottom-0 left-0 right-0 py-4 px-6 text-secondary/60 text-sm text-center bg-primary border-t border-white/10">
                &copy; Copyright {new Date().getFullYear()}, All Rights Reserved
                by main10
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
